import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Section from "../components/layout/section";

import * as styles from "./policy.module.scss";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { SEOv2 } from "../components/seov2";

const TermsOfUsePage = ({ data }) => (
  <Layout>
    <Section type="white">
      <div>
        <ReactMarkdown className={styles.markdown}>
          {data.allStrapiTermsOfUse.edges[0].node.termsOfUse.data.termsOfUse}
        </ReactMarkdown>
      </div>
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiTermsOfUse {
      edges {
        node {
          termsOfUse {
            data {
              termsOfUse
            }
          }
        }
      }
    }
  }
`;

export default TermsOfUsePage;

export const Head = () => (
  <SEOv2 title="Общи условия">
    <meta name="robots" content="noindex" />
  </SEOv2>
);
